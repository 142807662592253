import { graphql, useStaticQuery } from 'gatsby'

export const useProbateFAQ = (): GatsbyTypes.ContentfulFaqSection => {
  const { faqSection } = useStaticQuery<{
    faqSection: GatsbyTypes.ContentfulFaqSection
  }>(graphql`
    query {
      faqSection: contentfulFaqSection(
        contentful_id: { eq: "6AjgUzyofUJkQjLCk3vOO7" }
      ) {
        ...FaqSectionFields
      }
    }
  `)

  return faqSection
}
