import React, { forwardRef, ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { Wrapper, H, P, Divider } from '@farewill/ui'
import { COLOR, BORDER, FONT, GTR } from '@farewill/ui/tokens'
import { screenMin, screenMax } from '@farewill/ui/helpers/responsive'

import { formatPriceInPounds } from 'lib/formatting/pricing'
import PreventOrphan from 'components/PreventOrphan'

import DecorativeList from './DecorativeList'

const StyledOption = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  box-shadow: ${BORDER.SHADOW.M};
  border-radius: ${BORDER.RADIUS.XL};
`

const StyledOptionTop = styled.div`
  flex: 1 0 auto;
`

const StyledOptionBottom = styled.div`
  flex: none;
`

const StyledHeader = styled(Wrapper)`
  ${screenMax.m`
    min-height: 200px;
  `}
`

const StyledTitle = styled(H)`
  margin-bottom: ${GTR.S};
  padding-right: ${GTR.M};
`

const StyledPriceWrapper = styled(Wrapper)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${COLOR.BLACK};
`

const StyledPrice = styled.p<{ $priceBottomNote?: boolean }>`
  font-family: ${FONT.FAMILY.DECORATIVE};
  font-size: ${FONT.SIZE.XL};

  span {
    padding-left: ${GTR.XXS};
    font-size: ${FONT.SIZE.M};
    font-family: ${FONT.FAMILY.BODY};
  }

  ${({ $priceBottomNote }) => `
    ${$priceBottomNote ? 'margin-bottom: 0; line-height: 1.2;' : ''}
  `}

  ${screenMin.m`
    font-size: ${FONT.SIZE.XXXL};
  `}

  ${screenMin.l`
    font-size: ${FONT.SIZE.XXXL};
  `}
`

const StyledDescriptionWrapper = styled(Wrapper)<{ $height?: number }>`
  ${({ $height }) =>
    $height &&
    css`
      ${screenMin.m`
        min-height: ${$height}px;
      `}
    `}
`

const StyledDescription = styled(P)`
  font-size: ${FONT.SIZE.M};

  ${screenMin.l`
    font-size: ${FONT.SIZE.L};
  `};
`

type OptionProps = {
  title: string
  priceNote: string
  priceBottomNote?: string
  price: number
  description: string
  descriptionBoxHeight?: number
  list: {
    title: string
    items: string[]
  }[]
  children: ReactNode
}

const Option = forwardRef<HTMLDivElement, OptionProps>(
  (
    {
      title,
      priceNote,
      priceBottomNote,
      price,
      description,
      descriptionBoxHeight,
      children,
      list,
    },
    ref
  ) => (
    <StyledOption
      background={COLOR.WHITE}
      padding="M"
      paddingFromM="M"
      paddingFromL="L"
      paddingFromXL="XL"
      tag="article"
    >
      <StyledOptionTop>
        <StyledHeader tag="header" margin={[0]} marginFromM={[0, 0, 'M', 0]}>
          <StyledTitle
            tag="h3"
            size="M"
            margin={[0, 0, 'S']}
            marginFromM={[0, 0, 'L']}
            marginFromL={[0, 'XL', 'L', 0]}
          >
            {title}
          </StyledTitle>

          <Divider margin={['S', 0, 'S']} marginFromM={['S', 0, 'M']} />

          <StyledPriceWrapper margin={[0, 0, 'XS']}>
            <Wrapper>
              <P
                margin={[0, 'M', 0, 0]}
                marginFromL={priceBottomNote ? 0 : [0, 0, 'XS']}
                size={priceBottomNote ? 'L' : 'M'}
                strong
              >
                <PreventOrphan>{priceNote}</PreventOrphan>
              </P>
              <StyledPrice $priceBottomNote={!!priceBottomNote}>
                {formatPriceInPounds(price)}
              </StyledPrice>
              {priceBottomNote && (
                <P
                  margin={[0, 'M', 0, 0]}
                  marginFromL={[0, 0, 'XS']}
                  size="M"
                  strong
                >
                  <PreventOrphan>{priceBottomNote}</PreventOrphan>
                </P>
              )}
            </Wrapper>
          </StyledPriceWrapper>
          <div ref={ref}>
            <StyledDescriptionWrapper $height={descriptionBoxHeight}>
              <StyledDescription>{description}</StyledDescription>
            </StyledDescriptionWrapper>
          </div>
        </StyledHeader>
      </StyledOptionTop>
      <Divider margin={['S', 0]} marginFromM={['S', 0, 'M']} />
      <DecorativeList listItems={list} />
      <StyledOptionBottom>{children}</StyledOptionBottom>
    </StyledOption>
  )
)

/**
 * This displayName prop is here to fix the eslint issue, more in the thread:
 * https://stackoverflow.com/questions/67992894/component-definition-is-missing-display-name-for-forwardref
 */
Option.displayName = 'Option'

export default Option
