import React from 'react'
import styled from 'styled-components'
import {
  Wrapper,
  Image,
  TickIcon,
  H,
  Grid,
  P,
  BulletedList,
} from '@farewill/ui'
import { screenMin } from '@farewill/ui/helpers/responsive'
import { COLOR } from '@farewill/ui/tokens'

import PreventOrphan from 'components/PreventOrphan'
import CallUsTodayCta from 'components/CallUsTodayCta'
import { PRODUCTS } from 'lib/products/constants'
import useProductSupportStatus from 'lib/time/useProductSupportStatus'
import data from './data'

const StyledIcon = styled(TickIcon)`
  align-self: center;
  margin: 0;
`
const StyledP = styled(P)`
  margin: -8px;
`

const StyledGrid = styled(Grid)`
  align-items: center;

  ${screenMin.m`
    padding-bottom: 0px;
  `}
`

const PreferToTalk = (): React.ReactElement | null => {
  const isProductSupportOpen = useProductSupportStatus(PRODUCTS.PROBATE)
  const content = data

  return (
    <Wrapper>
      <StyledGrid container marginFromL={[0, 'auto']}>
        <Grid.Item spanFromM={7} startRow={2} startRowFromM={1}>
          <H size="L" tag="h2" decorative>
            <PreventOrphan>
              {isProductSupportOpen ? content.title : content.OutOfHoursTitle}
            </PreventOrphan>
          </H>
          {content.description && (
            <P size="L" maxWidthInColumns={6}>
              {content.description}
            </P>
          )}

          <BulletedList
            icon={
              <StyledIcon
                background={COLOR.ACCENT.PRIMARY}
                color={COLOR.BLACK}
                centered
              />
            }
            padding={['XXS', 0]}
          >
            {content.checklistItems.map((item) => (
              <BulletedList.Item key={item}>
                <StyledP size="L">
                  <PreventOrphan>{item}</PreventOrphan>
                </StyledP>
              </BulletedList.Item>
            ))}
          </BulletedList>
          <Wrapper margin={['L', 0, 0]}>
            <CallUsTodayCta product={PRODUCTS.PROBATE} />
          </Wrapper>
        </Grid.Item>
        <Grid.Item
          startRow={1}
          spanFromM={5}
          centered
          paddingFromL={[0, 0, 'XXL']}
        >
          <Image
            path={content.image}
            ext="jpg"
            width={280}
            widthFromL={400}
            stretch
          />
        </Grid.Item>
      </StyledGrid>
    </Wrapper>
  )
}

export default PreferToTalk
