import React from 'react'
import styled, { css } from 'styled-components'
import { Grid, H, Image, P, Wrapper } from '@farewill/ui'
import { screenMax } from '@farewill/ui/helpers/responsive'
import { COLOR } from '@farewill/ui/tokens'
import ProductContact from 'components/ProductContact'
import { PRODUCTS } from 'lib/products/constants'

const StyledH = styled(H)`
  ${screenMax.m`
    font-size: 18px;
  `}
`

const StyledImageWrapper = styled(Wrapper)<{ $isExperimental: boolean }>`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  ${({ $isExperimental }) => css`
    img {
      width: ${$isExperimental ? '420px' : '480px'};
      ${$isExperimental ? 'object-fit: contain;' : ''}
      max-width: 100%;
    }

    ${screenMax.s`
  justify-content: center;
    img {
      width: 320px;
      height: ${$isExperimental ? '320px' : 'auto'};
    }
  `}
  `}
`

const StyledProductContact = styled(ProductContact)`
  ${screenMax.s`
    & > div {
      width: 100%;
    }
  `}
`

const StyledGrid = styled(Grid)`
  align-items: center;
  ${screenMax.s`
  & > :nth-child(1) {
    order: 2;
  }
  & > :nth-child(2) {
    order: 3;
  }
`}
`

interface ExplainerProps {
  cta?: { text: string; path: string }
}

const Explainer = ({ cta }: ExplainerProps): React.ReactElement | null => (
  <Wrapper container containerPaddingTop={0}>
    <StyledGrid paddingFromL={['XL', 0, 0, 0]}>
      <Grid.Item span={12} centeredFromM>
        <H size="M" tag="h2" decorative>
          What is probate?
        </H>
      </Grid.Item>
      <Grid.Item spanFromM={5}>
        <H size="XS" tag="h3">
          Probate is a legal document
        </H>
        <P color={COLOR.BLACK}>
          It gives you the authority to handle the money, property and personal
          possessions (the estate) of the person who has died.
        </P>
        <H size="XS" tag="h3">
          You may need to apply for probate even if there is a will
        </H>
        <P color={COLOR.BLACK}>
          If someone has died and you’re named as an executor in the will, or
          you’re an administrator, you may need to apply for probate.
        </P>
      </Grid.Item>
      <Grid.Item spanFromM={6}>
        <StyledImageWrapper $isExperimental>
          <Image
            path="illustrations/estate-admin-house-paperwork"
            width={480}
            height={350}
          />
        </StyledImageWrapper>
      </Grid.Item>
    </StyledGrid>

    {cta && (
      <Wrapper padding={['L', 0, 0]} paddingFromL={['L', 0, 0, 'XL']} centered>
        <StyledH tag="h3" size="S" margin={['M', 0]}>
          Find out if you need probate
        </StyledH>
        <StyledProductContact
          telephoneNumberSize="S"
          selectedProduct={PRODUCTS.PROBATE}
          highlightTelephoneNumber
          hideCta
        />
      </Wrapper>
    )}
  </Wrapper>
)

export default Explainer
