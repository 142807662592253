import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { Image } from '@farewill/ui'
import { BREAKPOINT } from '@farewill/ui/tokens'
import { AWARD_LOGO_URL, AWARD_STRAPLINE } from 'lib/awards/constants'

const StyledImage = styled(Image)`
  ${(props) => props.stretch && `height: 100%; max-height: ${props.height}px;`}

  @media screen and (max-width: ${BREAKPOINT.S}px) {
    width: ${(props) => props.mobileWidth && `${props.mobileWidth}px;`};
    height: ${(props) => props.mobileHeight && `${props.mobileHeight}px;`};
  }
`

const AccoladesImage = ({
  path = AWARD_LOGO_URL.ACCOLADES_LAW_WILLS_PROBATE_WITH_SPACED_SHADOWS_2024,
  width = 405,
  height = 73,
  mobileWidth = 308,
  mobileHeight = 60,
  alt = AWARD_STRAPLINE.ACCOLADES_LAW_WILLS_PROBATE,
  stretch = true,
}: {
  path?: string
  width?: number
  height?: number
  mobileWidth?: number
  mobileHeight?: number
  alt?: string
  stretch?: boolean
}): ReactElement => (
  <StyledImage
    path={path}
    width={width}
    height={height}
    mobileWidth={mobileWidth}
    mobileHeight={mobileHeight}
    alt={alt}
    stretch={stretch}
  />
)

export default AccoladesImage
